*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 8px;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
}
